import { Badge } from '../Badge'

export default class Badges {
  static Draft: Badge = {
    cssClass: 'gray-status',
    name: 'Draft',
    description: 'The job is saved and not visible to anyone except you',
  }
  static Moderation: Badge = {
    name: 'Moderation',
    cssClass: 'orange-status',
    description: 'Your Job is on pre-moderation. You will receive a notification within the next 12 hours that your Job has been checked.',
  }
  static Failed: Badge = {
    name: 'Declined',
    cssClass: 'red-status',
    // eslint-disable-next-line max-len
    description: 'Our moderation team determined that your Job violated our rules of engagement. If you have any concerns, feel free to contact our support team.',
  }
  static Published: Badge = {
    name: 'Choosing Talent',
    cssClass: 'blue-status',
    description: 'The job was successfully published and is visible to everyone',
  }
  static Started: Badge = {
    name: 'Hired',
    cssClass: 'blue-status',
    description: 'The offer was successfully accepted',
  }
  static InProgress: Badge = {
    cssClass: 'blue-status',
    name: 'In progress',
    description: 'Customer has escrowed funds and freelancer is working on the task',
  }
  static IsDone: Badge = {
    name: 'Marked as Done',
    cssClass: 'blue-status',
  }
  static Dispute: Badge = {
    cssClass: 'red-status',
    name: 'In dispute',
    description: 'Dispute initiated by freelancer - job frozen',
  }
  static Resolved: Badge = {
    cssClass: 'blue-status',
    name: 'Resolved',
    description: 'Dispute resolved by third-party arbitration',
  }
  static Review: Badge = {
    cssClass: 'red-status',
    name: 'Waiting for feedback',
  }
  static Refunded: Badge = {
    cssClass: 'blue-status',
    name: 'Refunded',
    description: 'Task incomplete/unsatisfactory and funds returned to customer',
  }
  static Completed: Badge = {
    cssClass: 'blue-status',
    name: 'Completed',
    description: 'Task completed and funds paid to freelancer',
  }
}
