import { Badge } from '../Badge'

export default class Badges {
  static Declined: Badge = {
    cssClass: 'red-status',
    name: 'Declined',
    description: 'Your job listing has been unsuccessful. Please edit your listing and try again, or contact support for assistance.',
  }
  static Draft: Badge = {
    name: 'Draft',
  }
  static Moderation: Badge = {
    name: 'On moderation',
    cssClass: 'orange-status',
    description: 'Your job listing is currently in moderation. We will email you once an outcome has been reached.',
  }
  static Published: Badge = {
    name: 'Published',
    cssClass: 'blue-status',
  }
  static Archived: Badge = {
    name: 'Archived',
    description: 'You have already selected a freelancer and your job is in progress.'
  }
}
