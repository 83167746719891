import BigNumber from 'bignumber.js'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import Job from '@/models-ts/job/Job'
import { ModerationStages } from '@/constants/backend/ModerationStages'

export const MIN_BUDGET = 15
export const MAX_BUDGET = 15000
export const MIN_BUDGET_ERROR = 'The budget must be more than $14.99'

export const MAX_LENGTH_TITLE = '60'

export const DESC_MIN = 50
export const DESC_MAX = 3000
export const DESC_MIN_ERROR = `The description field must be at least ${DESC_MIN} characters`
export const DESC_MAX_ERROR = `The description field must not be greater than ${DESC_MAX} characters`

type JobType = Job & MyCustomerJobListItem

export function canPublish (job: JobType) {
  const moderationStage = job.moderation_stage || job.moderationStage
  return moderationStage !== ModerationStages.FAILED && !job.wasStarted
}

export function validateJobPublish (job: JobType) {
  const isCorrectBudget = new BigNumber(job.budget).gte(15)
  if (!isCorrectBudget) {
    return 'The budget must be more than $14.99'
  }
  const skills = job.relations?.Skill || job.skills
  if (!skills?.length) {
    return 'The Skills field is required'
  }
  if (!job.description) {
    return 'The description field must be at least 50 characters'
  }
}
