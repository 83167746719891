import Vue, { PropType } from 'vue'
import Currency from '@/models-ts/Currency'
import { Blockchain, BlockchainNames } from '@/constants/blockchain'

export default Vue.extend<any, any, any, any>({
  props: {
    currencies: {
      type: Array as PropType<Array<Currency>>,
      default: [],
    },
  },
  computed: {
    currenciesLabel () {
      return this.currencies
        .map((c: any) => c.name)
        .slice(0, 3)
        .join(', ')
    },
    currencyMore () {
      return this.currencies.length > 3
        ? ` +${this.currencies.length - 3}`
        : null
    },
    currenciesByBlockchain () {
      return this.currencies.reduce((acc: any, curr: Currency) => {
        acc[curr.blockchain] = (acc[curr.blockchain] || []).concat(curr.name)
        return acc
      }, {})
    },
    availableBlockchains () {
      return Object
        .keys(this.currenciesByBlockchain)
        .map((key: string) => ({
          key,
          name: BlockchainNames[Number(key) as Blockchain]
        }))
    },
  },
})
